<template>
  <div class="container claim">
    <img v-if="img_src" :src="img_src" class="w-50 img-header" />
    <h2>{{ text }}</h2>
    <form @submit.prevent="send" class="form" v-if="!sended">
      <div class="mb-3">
        <Multiselect
          :options="places"
          label="name"
          valueProp="id"
          v-model="data_to_send.place"
          searchable
          trackBy="name"
        />
      </div>
      <div class="mb-3">
        <textarea
          class="form-control"
          v-model="data_to_send.commentary"
          rows="4"
          placeholder="Text"
        />
      </div>
      <div class="mb-3">
        <input
          class="form-control"
          type="tel"
          v-model="data_to_send.ouid"
          placeholder="OneID"
        />
      </div>
      <div class="mb-3">
        <input
          class="form-control"
          type="tel"
          v-model="data_to_send.phone"
          placeholder="Phone"
        />
      </div>

      <div class="mb-3">
        <button type="submit" class="btn btn-primary w-100">Send</button>
      </div>
    </form>
    <div v-else>
      <h2>Спасибо за заявку!</h2>
      <button class="btn btn-link" @click="reset_form()">Вернуться</button>
    </div>
  </div>
</template>

<script>
import { ref } from "@vue/reactivity";
import { useStore } from "vuex";
import { computed, onMounted } from "@vue/runtime-core";
import { useRoute } from "vue-router";
import Multiselect from "@vueform/multiselect";
export default {
  components: {
    Multiselect,
  },
  setup() {
    const store = useStore();
    const route = useRoute();

    const places = ref([]);
    const filtered_places = computed(() =>
      places.value.filter((p) => !p.is_disabled)
    );

    const data_to_send = ref({
      place: null,
      commentary: "",
      ouid: "",
      phone: "",
    });

    const fetch_places = async () => {
      places.value = await store.dispatch("request", {
        url: "place",
        method: "GET",
      });
    };
    let sended = ref(false);

    const send = () => {
      store.dispatch("send_claim", data_to_send.value).then((r) => {
        if (r) {
          sended.value = true;
        }
      });
    };
    const description = computed(() => {
      const place = data_to_send.value.place;
      let ret = "";
      if (place) {
        ret = places.value.find((p) => p.id == place);
      }
      return ret;
    });
    onMounted(fetch_places);
    onMounted(() => {
      if (route.query) {
        data_to_send.value.place = route.query.place || null;
      }
    });
    const current_url = computed(
      () =>
        `${document.location.origin}/#/?place=${description?.value.id || null}`
    );
    const reset_form = () => {
      sended.value = false;
      data_to_send.value.commentary = "";
      data_to_send.value.ouid = "";
      data_to_send.value.phone = "";
    };

    let img_src = ref(null);
    let text = ref("Проблема? Напишите!");
    const load_text = () => {
      store
        .dispatch("req", {
          url: "settings/text/",
          method: "GET",
        })
        .then((r) => r.json())
        .then((r) => {
          console.log("loaded text", r);
          text.value = r.value;
        });
      store
        .dispatch("req", {
          url: "images/",
        })
        .then((r) => r.json())
        .then((r) => {
          const img = r.find((i) => i.default);
          if (img) {
            img_src.value = img.image;
          }
        });
    };
    onMounted(load_text);
    return {
      places: filtered_places,
      data_to_send,
      send,
      description,
      current_url,
      sended,
      reset_form,
      text,
      img_src,
    };
  },
};
</script>

<style lang="scss">
.claim {
  .img-header {
    padding-top: 1.5em;
    padding-bottom: 1em;
  }

  font-size: 1.6em;
  input,
  select,
  textarea,
  button {
    font-size: 1em;
  }
  .multiselect.is-single {
    .multiselect-single-label {
      font-size: 1.4em;
    }
  }
}
</style>
